<template>
	<v-container
		id="CasinoGroup Component"
		fluid
		tag="section">
		<v-row dense>
			<v-dialog
				width="40%"
				class="col-6 ml-auto mr-auto"
				v-model="createDialog">
				<v-card>
					<v-card-title :class="'headline primary py-3 mb-3'">
						Create Permission Group
					</v-card-title>
					<v-card-text>
						<v-form
							ref="form"
							v-model="validEdit">
							<v-text-field
								autofocus
								:rules="[rules.required, rules.minMaxLength(1, 40), noSameName]"
								v-model="textField"
								label="Name"></v-text-field>
							<EditComponent
								:fullArray="casinoList.data"
								:selectedArray="newCasinos"
								:searchIn="[`casino_id`]"
								noDialog
								noModel
								ref="createComponent"
								:loading="false"
								valueKey="casino_id"
								labelKey="website"
								:width="500"
								title="Edit Casino List"
								placeholderText="Casinos"
								color="primary"
								v-on:submitEditComponent="createGroup">
								<template #appendItem="{ item }">
									<v-chip>ID: {{ item.casino_id }}</v-chip>
								</template>
								<template #actions-wrapper="{ item }">
									<v-chip>ID: {{ item.casino_id }}</v-chip>
								</template>
							</EditComponent>
						</v-form>
					</v-card-text>
					<v-card-actions>
						<v-btn
							@click="createDialog = false"
							text>
							Cancel
						</v-btn>
						<v-spacer></v-spacer>
						<v-btn
							@click="unholyCrap"
							:disabled="!validEdit"
							color="primary"
							text>
							Submit
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<v-col>
				<v-card class="v-card--material pa-3">
					<CardHeading
						:loading="loading"
						:loadingTitle="`Loading casino group...`"
						:color="'secondary'"
						title="Casinos"></CardHeading>
					<v-row class="mb-2 align-center">
						<v-col
							cols="9"
							md="4"
							class="pa-0 pl-2">
							<v-autocomplete
								class="ml-2 mr-2 mr-md-3"
								eager
								:loading="loading"
								:disabled="loading"
								label="Groups"
								v-model="selectedItem"
								item-text="name"
								@change="rowClickHandle"
								:items="list"></v-autocomplete>
						</v-col>
						<v-col
							cols="3"
							md="4"
							class="px-0 pt-0 pb-2 pb-md-4">
							<ConfirmDialog2
								v-if="list.length > 0"
								persistent
								:confirmBtnText="'Delete'"
								:confirmBtnColor="'error'"
								:cancelBtnColor="'kajot-text'"
								:cancelBtnText="'Back'"
								:shouldShow="true"
								@confirm="deleteItem">
								<template v-slot:body>
									This casino group will be deleted.
									<br />
									Do you wish to continue ?
								</template>
								<template v-slot:default="{ showConfirm }">
									<v-btn
										v-if="
											_usrFlagsSome({
												key: 'management.groups.detail',
												val: permissions.DELETE,
											})
										"
										fab
										:small="!isMobile"
										:x-small="isMobile"
										class="error mr-1 mr-md-3"
										@click="showConfirm">
										<v-icon>mdi-delete</v-icon>
									</v-btn>
								</template>
							</ConfirmDialog2>
							<v-btn
								v-if="
									_usrFlagsSome({
										key: 'management.groups.detail',
										val: permissions.CREATE,
									})
								"
								fab
								:small="!isMobile"
								:x-small="isMobile"
								class="primary"
								@click="createDialog = true">
								<v-icon>mdi-plus</v-icon>
							</v-btn>
						</v-col>

						<v-col
							v-if="
								_usrFlagsSome({
									key: 'management.groups.detail',
									val: permissions.EDIT,
								})
							"
							cols="12"
							md="4">
							<EditComponent
								:fullArray="casinoList.data"
								:selectedArray="groupCasinos"
								:loading="loading"
								noDialog
								noModel
								:maxChips="3"
								:searchIn="[`casino_id`]"
								ref="editComponent"
								valueKey="casino_id"
								labelKey="website"
								:width="200"
								title="Edit Casino List"
								placeholderText="Casinos"
								color="primary"
								v-on:submitEditComponent="editGroup">
								<template #appendItem="{ item }">
									<v-chip>ID: {{ item.casino_id }}</v-chip>
								</template>
							</EditComponent>
						</v-col>
					</v-row>
					<v-divider class="mb-3"></v-divider>
					<v-skeleton-loader
						v-if="loading"
						type="image@2"></v-skeleton-loader>
					<div
						v-else
						class="d-flex justify-center">
						<v-row
							dense
							class="d-flex">
							<v-col
								class="pb-2"
								cols="12"
								:key="item.website"
								v-for="(item, index) in groupCasinos.sort(
									(a, b) => a.casino_id - b.casino_id
								)"
								md="6"
								lg="4"
								xl="3">
								<v-card
									class="elevation-1 ma-0"
									:class="{ 'mb-4': index < groupCasinos.length - 1 }">
									<v-card-title
										class="secondary d-flex align-center justify-space-between py-2 body-1">
										{{ item.website.toUpperCase() }}
										<v-chip>ID: {{ item.casino_id }}</v-chip>
									</v-card-title>
									<v-card-text class="py-1">
										<div>Country: {{ item.country }}</div>
									</v-card-text>
								</v-card>
							</v-col>
						</v-row>
					</div>
					<v-card-actions
						v-if="
							_usrFlagsSome({
								key: 'management.groups.detail',
								val: permissions.EDIT,
							})
						"
						class="stickyButtons">
						<v-spacer></v-spacer>

						<v-btn
							@click="$refs.editComponent.reset()"
							:disabled="disableSubmit"
							text>
							<v-icon
								fab
								small
								class="primary--text mr-1">
								mdi-restore
							</v-icon>
							Reset
						</v-btn>
						<v-btn
							@click="$refs.editComponent.submit()"
							:disabled="disableSubmit"
							text>
							Submit
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import permissions from "../../mixins/permissions";
import EditComponent from "../../components/jackpots/EditComponent.vue";
import CardHeading from "../../components/shared/CardHeading.vue";
import { rules as defaultRules } from "../../plugins/rules.ts";
import ConfirmDialog2 from "../../components/shared/ConfirmDialog2.vue";
import { isMobile } from "../../constants/helpers";
import _ from "lodash";

export default {
	components: {
		CardHeading,
		EditComponent,
		ConfirmDialog2,
	},
	mixins: [permissions],
	data() {
		return {
			validEdit: false,
			loading: true,
			rules: { ...defaultRules },
			noSameName: (value) => {
				return (
					(!this.list
						.map((el) => el.name.toLowerCase())
						.includes(value.toLowerCase()) &&
						!this.perms
							.map((el) => el.name.toLowerCase())
							.includes(value.toLowerCase())) ||
					"Group already exists."
				);
			},
			changedPerms: {},
			textField: "",
			newCasinos: [],
			createDialog: false,
			selectedItem: "",
			modifyGroup: false,
			permGroupHeaders: [{ text: "Name", value: "name", align: "center" }],
		};
	},
	computed: {
		isMobile,
		...mapGetters(["casinoList"]),
		...mapGetters("permissions", {
			perms: "list",
			list: "casinoGroups",
			detail: "detail",
		}),
		disableSubmit() {
			return (
				this.loading ||
				_.isEqual(
					this.$refs?.editComponent?._selected.concat().sort((a, b) => a - b),
					this?.groupCasinos.map((el) => el.casino_id)
				)
			);
		},
		groupCasinos() {
			return (
				this.casinoList?.data?.filter((casino) =>
					this.detail?.casinos?.includes(casino.casino_id)
				) ?? []
			);
		},
	},
	methods: {
		...mapActions(["getCasinoList"]),
		...mapActions("permissions", {
			loadPerms: "loadList",
			loadList: "loadCasinoList",
			loadDetail: "loadDetail",
			create: "create",
			edit: "edit",
			delete: "delete",
		}),
		async unholyCrap() {
			this.$refs.createComponent.submit();
			this.createDialog = false;
			this.newCasinos = [];
		},
		async deleteItem() {
			await this.delete(this.selectedItem);
			await this.loadList();
			await this.loadPerms();
			if (this.list.length <= 0) return;
			const preselect = this.$route.query.selected;
			let preselectIndex = this.list.findIndex((el) => el.name === preselect);
			if (preselectIndex === -1) preselectIndex = 0;
			this.selectedItem = this.list[preselectIndex].name;
			await this.loadDetail(this.selectedItem);
		},
		async rowClickHandle(e) {
			if (!e) return;
			this.loading = true;
			this.selectedItem = e.toLowerCase();
			await this.loadList();
			await this.loadPerms();
			await this.loadDetail(e);
			this.loading = false;
		},
		async createGroup(values) {
			await this.create({
				data: {
					casinos: values,
					name: this.textField,
					app: "logan",
				},
			});
			this.onEditCasinosSubmit(this.textField);
		},
		async editGroup(values) {
			await this.edit({
				data: {
					sections: this.detail.sections,
					casinos: values,
					name: this.selectedItem.toLowerCase(),
					app: "logan",
				},
			});
			this.onEditCasinosSubmit(this.selectedItem.toLowerCase());
		},
		async onEditCasinosSubmit(name) {
			this.loading = true;
			await this.loadList();
			await this.loadPerms();
			this.selectedItem = name;
			await this.loadDetail(this.selectedItem);
			this.loading = false;
			this.textField = "";
		},
	},
	async created() {
		this.loading = true;
		await this.loadList();
		await this.loadPerms();
		if (this.list.length <= 0) return;
		const preselect = this.$route.query.selected;
		let preselectIndex = this.list.findIndex((el) => el.name === preselect);
		if (preselectIndex === -1) preselectIndex = 0;
		this.selectedItem = this.list[preselectIndex].name;
		await this.loadDetail(this.selectedItem);
		this.loading = false;
	},
};
</script>

<style scoped>
::v-deep tr.v-data-table__selected {
	background-color: var(--v-primary-base) !important;
}

.stickyButtons {
	background-color: var(--v-defaultbg-base);
	position: sticky;
	z-index: 1;
	bottom: 14px;
}
</style>
